import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import {
  InputTextField,
  TableHeaders,
  getData,
} from "../components/AlumniElements";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LinkedInBlue from "../images/linkedin-blue-no-outline.png";

function Alumni() {
  const [alumniData, setAlumniData] = useState([]);
  const [originalAlumniData, setOriginalAlumniData] = useState([]);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");

  useEffect(() => {
    getData().then((data) => {
      setAlumniData(data);
      setOriginalAlumniData(data);
    });
  }, []);

  const nameFilter = (search_name) => {
    setName(search_name);
    const filteredData = originalAlumniData.filter((row) => {
      if (
        row.name.toLowerCase().includes(search_name.toLowerCase()) &&
        row.current_position.toLowerCase().includes(position.toLowerCase()) &&
        row.current_company.toLowerCase().includes(company.toLowerCase())
      ) {
        return row;
      }
    });
    setAlumniData(filteredData);
  };

  const positionFilter = (search_position) => {
    setPosition(search_position);
    const filteredData = originalAlumniData.filter((row) => {
      if (
        row.name.toLowerCase().includes(name.toLowerCase()) &&
        row.current_position
          .toLowerCase()
          .includes(search_position.toLowerCase()) &&
        row.current_company.toLowerCase().includes(company.toLowerCase())
      ) {
        return row;
      }
    });
    setAlumniData(filteredData);
  };

  const companyFilter = (search_company) => {
    setCompany(search_company);
    const filteredData = originalAlumniData.filter((row) => {
      if (
        row.name.toLowerCase().includes(name.toLowerCase()) &&
        row.current_position.toLowerCase().includes(position.toLowerCase()) &&
        row.current_company.toLowerCase().includes(search_company.toLowerCase())
      ) {
        return row;
      }
    });
    setAlumniData(filteredData);
  };

  return (
    <div>
      <Navbar></Navbar>
      <br />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Alumni List
      </Typography>
      <br />
      <Container>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Below is a list of alumni who have been a part of the Silver Fund.
          Feel free to reach out to any of the alumni below to learn more about
          their experience with BYU Silver Fund and the finance industry. You
          are able to filter the list by name, position, and company.
        </Typography>
      </Container>
      <br />
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TableHeaders>Alumni</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Current Position</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Current Company</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>Graduation Year</TableHeaders>
                </TableCell>
                <TableCell align="center">
                  <TableHeaders>LinkedIn</TableHeaders>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ height: "20px" }}>
              <TableRow>
                <TableCell>
                  <InputTextField
                    label="Name"
                    variant="outlined"
                    onChange={(e) => nameFilter(e.target.value)}
                    size="small"
                  ></InputTextField>
                </TableCell>
                <TableCell>
                  <InputTextField
                    label="Position"
                    variant="outlined"
                    onChange={(e) => positionFilter(e.target.value)}
                    size="small"
                  ></InputTextField>
                </TableCell>
                <TableCell>
                  <InputTextField
                    label="Company"
                    variant="outlined"
                    onChange={(e) => companyFilter(e.target.value)}
                    size="small"
                  ></InputTextField>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alumniData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.current_position}</TableCell>
                  <TableCell>{row.current_company}</TableCell>
                  <TableCell align="center">{row.graduation_year}</TableCell>
                  <TableCell align="center">
                    <a href={row.profile_url}>
                      <img
                        src={LinkedInBlue}
                        alt="LinkedIn Logo"
                        style={{ width: "20px" }}
                      />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
}

export default Alumni;
