import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Home from "./views/Home";
import Positions from "./views/Positions";
import Alumni from "./views/Alumni";
import About from "./views/About";
import Tools from "./views/Tools";
import Events from "./views/Events";
import Team from "./views/Team";

let theme = createTheme({
  palette: {
    secondary: {
      main: "#fefefe",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/positions">
            <Positions />
          </Route>
          <Route path="/tools">
            <Tools />
          </Route>
          <Route path="/alumni">
            <Alumni />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/events">
            <Events />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
