import { useEffect, useState } from "react";
import { Container, Typography, Grid } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Papa from "papaparse";

const getUnderCurrentPositions = async () => {
  const response = await fetch(
    process.env.REACT_APP_S3_UNDERGRAD_POSITIONS_OBJECT_URL
  );
  const reader = response.body.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder("utf-8");
  const csv = decoder.decode(result.value);
  const results = await Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
  });
  const rows = results.data;
  return rows;
};

const getMBACurrentPositions = async () => {
  const response = await fetch(
    process.env.REACT_APP_S3_GRAD_POSITIONS_OBJECT_URL
  );
  const reader = response.body.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder("utf-8");
  const csv = decoder.decode(result.value);
  const results = await Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
  });
  const rows = results.data;
  return rows;
};

const tickerCheck = (ticker) => {
  if (ticker[ticker.length - 1] === "-") {
    ticker = ticker.slice(0, -1);
    return ticker.toUpperCase();
  } else {
    return ticker.toUpperCase();
  }
};

const getPosInfo = async (positions) => {
  let total_equity = 0;
  let pos_info = [];
  positions.forEach((position) => {
    total_equity += parseFloat(position.positionvalue);
  });

  const promises = positions.map(async (position) => {
    pos_info.push({
      ticker: tickerCheck(position.Symbol),
      description: position.Description,
      weight: (parseFloat(position.positionvalue) / total_equity) * 100,
    });
  });
  await Promise.all(promises);
  pos_info.sort((a, b) => (a.weight < b.weight ? 1 : -1));

  return pos_info;
};

function Home() {
  const [underPositions, setUnderPositions] = useState([]);
  const [underPosInfo, setUnderPosInfo] = useState([]);
  const [mbaPositions, setMbaPositions] = useState([]);
  const [mbaPosInfo, setMbaPosInfo] = useState([]);

  useEffect(() => {
    getUnderCurrentPositions()
      .then((res) => {
        setUnderPositions(res);
        return res;
      })
      .then((underPositions) => getPosInfo(underPositions))
      .then((res) => setUnderPosInfo(res));

    getMBACurrentPositions()
      .then((res) => {
        setMbaPositions(res);
        return res;
      })
      .then((mbaPositions) => getPosInfo(mbaPositions))
      .then((res) => setMbaPosInfo(res));
  }, []);

  return (
    <div>
      <Navbar />
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Top Holdings
      </Typography>
      <br />
      <br />
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        Below is a list of the top 10 current positions held by the Silver Fund.
        <br />
        These include the positions held by the undergraduate and MBA teams.
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Container maxWidth="sm">
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Undergraduate Positions
          </Typography>
          <ImageList variant="masonry" cols={3} gap={15}>
            {underPosInfo.map((row, index) => (
              <a
                href={`https://finance.yahoo.com/quote/${row.ticker}`}
                style={{ textDecoration: "none", color: "rgb(0, 42, 92)" }}
              >
                <ImageListItem
                  key={row}
                  sx={{
                    borderRadius: "10px",
                    "&:hover": {
                      transform: "scale(0.95)",
                      transition: "all 0.3s ease-in-out",
                    },
                    border: "2px solid rgb(0, 42, 92)",
                    padding: "5px",
                  }}
                >
                  <img
                    srcSet={`/logos/${row.ticker}.png`}
                    src={`/logos/${row.ticker}.png`}
                    alt={row.ticker}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={row.ticker}
                    subtitle={
                      <div>
                        <span>Weight: {row.weight.toFixed(2)}%</span> <br />
                      </div>
                    }
                    position="below"
                  />
                </ImageListItem>
              </a>
            ))}
          </ImageList>
        </Container>
        <Container maxWidth="sm">
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            MBA Positions
          </Typography>
          <ImageList variant="masonry" cols={3} gap={15}>
            {mbaPosInfo.map((row, index) => (
              <a
                href={
                  row.ticker !== "ETF29" && row.ticker !== "cash"
                    ? `https://finance.yahoo.com/quote/${row.ticker}`
                    : row.ticker === "ETF29"
                    ? "https://finance.yahoo.com/quote/%5ERUA?p=^RUA&.tsrc=fin-srch"
                    : ""
                }
                style={{ textDecoration: "none", color: "rgb(0, 42, 92)" }}
              >
                <ImageListItem
                  key={row}
                  sx={{
                    borderRadius: "10px",
                    "&:hover": {
                      transform: "scale(0.95)",
                      transition: "all 0.3s ease-in-out",
                    },
                    border: "2px solid rgb(0, 42, 92)",
                    padding: "5px",
                  }}
                >
                  <img
                    srcSet={`/logos/${row.ticker}.png`}
                    src={`/logos/${row.ticker}.png`}
                    alt={row.ticker}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={row.ticker}
                    subtitle={
                      <div>
                        <span>Weight: {row.weight.toFixed(2)}%</span> <br />
                      </div>
                    }
                    position="below"
                  />
                </ImageListItem>
              </a>
            ))}
          </ImageList>
        </Container>
      </Grid>
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
}

export default Home;
