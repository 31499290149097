import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import {
  NavMenu,
  Bars,
  NavLink,
  Container,
  ByuLogo,
  SfLogo,
  Title,
  Item,
} from "./NavbarElements";

export default function Navbar(props) {
  const history = useHistory();
  const [showNav, setShowNav] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = () => {
    setShowNav(!showNav);
  };
  const handleClickLogin = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Item>
        <SfLogo dark={props.dark} onClick={() => history.push("/")}></SfLogo>
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          onClick={() => history.push("/")}
        >
          <ByuLogo dark={props.dark}></ByuLogo>
          <Title dark={props.dark}> Silver Fund </Title>
        </div>
        <Bars onClick={handleClick} dark={props.dark} />
      </Item>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NavMenu show={showNav} dark={props.dark}>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/events">News</NavLink>
          <NavLink to="/team">Team</NavLink>
          <NavLink to="/positions">Top Holdings</NavLink>
          <NavLink to="/alumni">Alumni</NavLink>
          <NavLink to="/tools">Tools</NavLink>
          {/*<Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClickLogin}
            style={{
              color: props.dark ? "#002A5C" : "white",
              backgroundColor: props.dark ? "white" : "#002A5C",
              fontFamily: "Open Sans",
              marginLeft: "1rem",
            }}
          >
            Logins
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disableRipple>
              <A href="https://www.47fund.byu.edu">Undergraduate</A>
            </MenuItem>
            <MenuItem disableRipple>
              <A href="https://www.mba.47fund.byu.edu">MBA</A>
            </MenuItem>
          </StyledMenu>*/}
        </NavMenu>
      </Grid>
    </Container>
  );
}
